import swal from "sweetalert";
import { surveyList } from "../../services/SurveyService";
import { getRecordInLocalStorage, saveRecordInLocalStorage } from "../../services/UtilService";

export function surveyListAction() {
    return surveyList().then((response) => {
        console.log(response.data);
        if (response.status == 200) {
            saveRecordInLocalStorage('survey-list-record', response.data);
            console.log(getRecordInLocalStorage('survey-list-record'));
        } else {
            // swal("Oops", response.data.message, "error");
        }
    }).catch((error) => {
        console.log(error);
    });
}