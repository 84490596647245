import React, { useEffect } from "react";

const Question = (props) => {

    useEffect(() => {
        checkBoxLimit();
    }, []);

    function checkBoxLimit() {
        var checkBoxGroup = document.forms['qa']['qa_' + props.qi + '[]'];
        //console.log(checkBoxGroup);
        var limit = props.maxSelection;
        if (typeof checkBoxGroup != 'undefined') {
            for (var i = 0; i < checkBoxGroup.length; i++) {
                checkBoxGroup[i].onclick = function () {
                    var checkedcount = 0;
                    for (var i = 0; i < checkBoxGroup.length; i++) {
                        checkedcount += (checkBoxGroup[i].checked) ? 1 : 0;
                    }
                    if (checkedcount > limit) {
                        console.log("You can select maximum of " + limit + " checkboxes.");
                        alert("You can select maximum of " + limit + " checkboxes.");
                        this.checked = false;
                    }
                }
            }
        }

    }

    return (
        <section>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <small>Question: {props.qi}</small>
                    <h3>{props.title}</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12" id={`answergroupq_` + props.qi}>
                    {props.choices.map((choices, i) => (
                        <>
                            {choices.imageUrl != "" ? <img src={choices.imageUrl} height="50" width="50" /> : ' '}
                            {choices.imageUrl != "" ? <br /> : ' '}
                            
                            <input
                                key={i}
                                className="form-check-input"
                                type="checkbox"
                                name={`qa_` + props.qi + `[]`}
                                value={choices.name}
                                onChange={props.handleForm}
                            />

                            <label className="form-check-label">
                                {choices.name}
                            </label>
                            <br />
                            {choices.allowInput ? <input type="text" className="form-control" onChange={props.handleForm} name={`oqa` + props.qi} value="" /> : ' '}

                        </>


                    ))}
                </div>
            </div>
        </section>
    );
};

export default Question;
