import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header: 'ID',
		Footer: 'ID',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Precinct No.',
		Footer: 'Precinct No.',
		accessor: 'precinct',
		// Cell: ({ value }) => { return format(new Date(value), 'dd/mm/yyyy') },
		Filter: ColumnFilter,
	},
	{
		Header: 'Voter`s Name',
		Footer: 'Voter`s Name',
		accessor: 'voter_name',
		Filter: ColumnFilter,
	},
	{
		Header: 'Date of Birth',
		Footer: 'Date of Birth',
		accessor: 'birthdate',
		Filter: ColumnFilter,
	},
	{
		Header: 'Address',
		Footer: 'Address',
		accessor: 'address',
		Filter: ColumnFilter,
	},
	{
		Header: 'Barangay',
		Footer: 'Barangay',
		accessor: 'barangay',
		Filter: ColumnFilter,
	},
	{
		Header: 'Action',
		Footer: 'Action',
		accessor: 'voter_hash_id',
		Filter: ColumnFilter,
	},
]