import axios from 'axios';
import React, { useState } from 'react'
import SEO from '../../common/SEO';
import apiConfig from '../../config/api.config';
import loginbg from "../../images/pic1.png"; //iamge
import { isLoggedIn } from '../../services/UtilService';
import { loginAction } from '../../store/actions/AuthActions';

function Login(props) {

    const [email, setEmail] = useState('dummy@gmail.com');
    const [password, setPassword] = useState('acmsadmin1');

    let errorsObj = { email: '', password: '', errormessage: '' };
    const [errors, setErrors] = useState(errorsObj);

    function onLogin(e) {
        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        // console.log(isLoggedIn());
        loginAction(email, password);
    }

    return (
        <>
            <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="login-aside text-center  d-flex flex-column flex-row-auto">
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                        <div className="text-center mb-4 pt-5">
                            <img src={process.env.PUBLIC_URL + '/images/logo-with-text.png'} height={80} />
                        </div>
                        <h3 className="mb-2">Welcome back!</h3>
                        <p></p>
                    </div>
                    <div className="aside-image" style={{ backgroundImage: "url(" + loginbg + ")" }}></div>
                </div>
                <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                    <div className="d-flex justify-content-center h-100 align-items-center">
                        <div className="authincation-content style-2">
                            <div className="row no-gutters">
                                <div className="col-xl-12 tab-content">
                                    <div id="sign-in" className="auth-form   form-validation">
                                        <form onSubmit={onLogin} className="form-validate">
                                            <h3 className="text-center mb-4 text-black">Sign in your account</h3>
                                            <div className="form-group mb-3">
                                                <label className="mb-1" htmlFor="val-email"><strong>Email</strong></label>
                                                <div>
                                                    <input type="email" className="form-control"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="Type Your Email Address"
                                                    />
                                                </div>
                                                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="mb-1"><strong>Password</strong></label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={password}
                                                    placeholder="Type Your Password"
                                                    onChange={(e) =>
                                                        setPassword(e.target.value)
                                                    }
                                                />
                                                {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                            </div>
                                            <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                <div className="form-group mb-3">
                                                    <div className="custom-control custom-checkbox ml-1">
                                                        <input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
                                                        <label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center form-group mb-3">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    Sign In
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login; 