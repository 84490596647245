import React, { Fragment, useEffect, useState } from "react";
import Multistep from "react-multistep";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { getRecordInLocalStorage, removeRecordInLocalStorage, saveRecordInLocalStorage } from "../../../../services/UtilService";
import { questionAnswerAction, saveQuestionAnswerAction } from "../../../../store/actions/QAAction";
import Question from "./Question";
const QuestionAnswer = (props) => {

    let { survey_hash_id, voter_hash_id, votername } = useParams();
    const [step, setStep] = useState([{ name: "", component: <Question title="" choices={[]} /> }]);
    const [form, setForm] = useState([{ survey_hash_id: survey_hash_id, voter_hash_id: voter_hash_id }]);

    useEffect(() => {
        questionAnswerAction(survey_hash_id, voter_hash_id).then((response) => {
            let q = [];
            for (var i = 0; i < response.length; i++) {
                q[i] = {
                    name: response[i].name,
                    component: <Question
                        title={response[i].name}
                        choices={response[i].choices}
                        qi={i}
                        maxSelection={response[i].maxSelection}
                        handleForm={handleForm}
                    />
                }

                saveRecordInLocalStorage('q_hashid_' + i, response[i].question_hash_id);
            }

            setStep(q);
            console.log(response);
        });
    }, []);

    const handleForm = (e) => {
        const { name, value } = e.target;
        saveRecordInLocalStorage(name, value);
        setForm([
            ...form,
            { [name]: value }
        ]);
    }

    const submit = (e) => {
        e.preventDefault();
        for (var i = 0; i < step.length; i++) {
            var qa = getRecordInLocalStorage('qa_' + i + '[]');
            var oqa = getRecordInLocalStorage('oqa' + i);
            var q_hashid = getRecordInLocalStorage('q_hashid_' + i);
            if (typeof qa != 'undefined') {
                let saveQA = {
                    survey_hash_id: survey_hash_id,
                    voter_hash_id: voter_hash_id,
                    question_hash_id: q_hashid,
                    answer: qa,
                    freetext: oqa,
                    // answer_hash_id: answer_hash_id
                };
                console.log(saveQA);
                saveQuestionAnswerAction(saveQA).then((response) => {
                    removeRecordInLocalStorage('qa_' + i);
                    removeRecordInLocalStorage('q_hashid_' + i);
                    swal("Success", 'Successfully submitted!', "success");

                    console.log(response);
                });
            } else {
                // swal("Success", 'Successfully submitted!', "success");
            }
        }
    }

    const steps = step;

    const prevStyle = {
        background: "#F7FAFC",
        borderWidth: "0px",
        color: "#333333",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "600",
        padding: "12px 18px",
        border: "1px solid #EEEEEE",
        marginRight: "1rem",
    };
    const nextStyle = {
        background: "#216fed",
        borderWidth: "0px",
        color: "#fff",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "600",
        padding: "12px 18px",
    };

    return (
        <>
            <Fragment>
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Voter's Name: {votername}</h4>
                            </div>
                            <div className="card-body">
                                <form
                                    onSubmit={e => { e.preventDefault() }}
                                    id="qa"
                                    className="step-form-horizontal"
                                    name="qa"
                                >

                                    <Multistep
                                        showNavigation={false}
                                        steps={steps}
                                        showTitles={false}
                                        prevStyle={prevStyle}
                                        nextStyle={nextStyle}
                                    />

                                    <button type="submit" onClick={submit} className="btn btn-primary mt-2">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    );
}

export default QuestionAnswer;