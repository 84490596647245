import { login, logout } from "../../services/AuthService";
import swal from "sweetalert";
import { clearCookie, getHashCode, getRecordInLocalStorage, isLoggedIn, removeRecordInLocalStorage, saveRecordInLocalStorage } from "../../services/UtilService";

export function loginAction(email, password) {
    return login(email, password).then((response) => {
        console.log(response.data.data);
        if (response.data.status) {
            saveRecordInLocalStorage('user-status', response.data.data);
            console.log(getRecordInLocalStorage('user-status'))
            window.location.replace('/dashbaord');
        } else {
            swal("Oops", response.data.message, "error");
        }
    }).catch((error) => {
        console.log(error);
    });
}

export function logoutAction() {
    return logout(getHashCode()).then((response) => {
        clearCookie();
        window.location.replace('/login');
    }).catch((error) => {
        console.log('error');
    });
}

export function authentication() {
    var status = true;
    if (typeof isLoggedIn() == 'undefined') {
        status = false;
    }

    return isLoggedIn();
}
