import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header: 'ID',
		Footer: 'ID',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Date Created',
		Footer: 'Date Created',
		accessor: 'created_at',
		Cell: ({ value }) => { return format(new Date(value), 'dd/mm/yyyy') },
		Filter: ColumnFilter,
	},
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header: 'Question Count',
		Footer: 'Question Count',
		accessor: 'questionCount',
		Filter: ColumnFilter,
	},
	{
		Header: 'Assigned Voters Count',
		Footer: 'Assigned Voters Count',
		accessor: 'assigned_voters_count',
		Filter: ColumnFilter,
	},
	{
		Header: 'Allowed Profiling Type Count',
		Footer: 'Allowed Profiling Type Count',
		accessor: 'allowed_profiling_type_count',
		Filter: ColumnFilter,
	},
	{
		Header: 'Action',
		Footer: 'Action',
		accessor: 'survey_hash_id',
		Filter: ColumnFilter,
	},
]