import axios from 'axios';
import apiConfig from '../config/api.config';
import { getHashCode, getToken } from './UtilService';

export function voterList(survey_hash_id) {
    let token = getToken();

    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return axios.get(
        apiConfig.based_url + "admin/voters/read/" + getHashCode() + "/" + survey_hash_id,
        headerConfig
    );
}