import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";

import App from './App';
import AuthContextProvider from './context/AuthContext';
import reportWebVitals from './reportWebVitals';
import ThemeContext from "./context/ThemeContext";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <SimpleReactLightbox>
        <BrowserRouter basename='/'>
          <ThemeContext>
            <App />
          </ThemeContext>
        </BrowserRouter>
      </SimpleReactLightbox>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();