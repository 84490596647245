import swal from "sweetalert";
import { getRecordInLocalStorage, saveRecordInLocalStorage } from "../../services/UtilService";
import { voterList } from "../../services/VoterService";

export function voterListAction(survey_hash_id) {
    return voterList(survey_hash_id).then((response) => {
        if (response.status == 200) {
            //console.log(response.data);
            return response.data;
            //saveRecordInLocalStorage('voter-list-record', response.data);
            console.log(getRecordInLocalStorage('voter-list-record'));
        }
    });
}