import React, { Component, createContext } from 'react';

export const AuthContext = createContext();

class AuthContextProvider extends Component {

    constructor(props) {
        super(props);

        var isLoggedIn = false;
        const _isAuthenticated = localStorage.getItem('isAuthenticated');
        if (_isAuthenticated == 'undefined') {
            isLoggedIn = false;      
            console.log('Yes' + _isAuthenticated);
        } else {
            isLoggedIn = _isAuthenticated;
            console.log('No' + _isAuthenticated);
        }
    }

    state = {
        isAuthenticated: this.isLoggedIn
    }

    auth = () => {
        console.log(this.state.isAuthenticated);
        localStorage.setItem('isAuthenticated', this.state.isAuthenticated);
        const _isAuthenticated = localStorage.getItem('isAuthenticated');
        console.log('here: '+ _isAuthenticated);
        if(this.state.isAuthenticated){
            this.setState({ isAuthenticated: false });
        } else {
            this.setState({ isAuthenticated: true });
        }
        // this.setState({ isAuthenticated: !this.state.isAuthenticated });
       
    }

    render() {
        return (
            <AuthContext.Provider value={{ ...this.state, auth: this.auth }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthContextProvider;