import axios from 'axios';
import apiConfig from '../config/api.config';

export function login(email, password) {
    let token = apiConfig.api_key;

    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }
    const bodyParams = {
        email,
        password,
        returnSecureToken: true,
    };

    return axios.post(
        apiConfig.based_url + "admin/login",
        bodyParams,
        headerConfig
    );
}

export function logout(hashCode) {
    let token = apiConfig.api_key;

    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return axios.get(
        apiConfig.based_url + "admin/logout/" + hashCode,
        headerConfig
    );
}