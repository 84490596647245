import Metismenu from "metismenujs";
import { Link } from "react-router-dom";
import React, { Component, useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import useScrollPosition from "use-scroll-position";

import { ThemeContext } from "../../../context/ThemeContext";

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new Metismenu(this.$el);
    }
    componentWillUnmount() { }
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const Sidebar = () => {

    const {
        iconHover,
        sidebarposition,
        headerposition,
        sidebarLayout,
    } = useContext(ThemeContext);

    useEffect(() => {
        var btn = document.querySelector(".nav-control");
        var aaa = document.querySelector("#main-wrapper");

        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle");
        }
        btn.addEventListener("click", toggleFunc);

        //sidebar icon Heart blast
        // var handleheartBlast = document.querySelector('.heart');
        // function heartBlast() {
        //     return handleheartBlast.classList.toggle("heart-blast");
        // }
        // handleheartBlast.addEventListener('click', heartBlast);

    }, []);

    let scrollPosition = useScrollPosition();

    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let deshBoard = [
        "",
        "survey-list",
    ]

    return (
        <div
            className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerposition.value === "static"
                ? scrollPosition > 120
                    ? "fixed"
                    : ""
                : ""
                }`}
        >
            <PerfectScrollbar className="deznav-scroll">
                <MM className="metismenu" id="menu">
                    <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow ai-icon" to="#" >
                            <i className="flaticon-025-dashboard"></i>
                            <span className="nav-text">Dashboard</span>
                        </Link>
                        <ul>
                            <li><Link className={`${path === "" ? "mm-active" : ""}`} to="/">Dashboard</Link></li>
                            <li><Link className={`${path === "survey-list" ? "mm-active" : ""}`} to="/survey-list">Survey List</Link></li>
                            {/* <li><Link className={`${path === "survey-list" ? "mm-active" : ""}`} to="/voter-list">Survey List</Link></li> */}

                        </ul>
                    </li>

                </MM>
                <div className="copyright">
                    <p><strong>ACMS Survey</strong> © 2021 All Rights Reserved</p>
                </div>
            </PerfectScrollbar>
        </div>
    );
}

export default Sidebar;