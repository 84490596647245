import React, { Fragment, useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Navigation = () => {

    const [toggle, setToggle] = useState("");
    const onClick = (name) => setToggle(toggle === name ? "" : name);

    return (
        <Fragment>
            <Navbar />
            <Header />
            <Sidebar />
        </Fragment>
    );
}

export default Navigation;