import axios from 'axios';
import apiConfig from '../config/api.config';
import { getHashCode, getToken } from './UtilService';

export function surveyList() {
    let token = getToken();

    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return axios.get(
        apiConfig.based_url + "admin/survey/read/" + getHashCode(),
        headerConfig
    );
}