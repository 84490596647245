import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './jsx/pages/Login'; //Temp.
import axios from "axios";
import apiConfig from "./config/api.config";
import Index from "./jsx";

// css
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './vendor/datatables/css/dataTables.min.css';
import "./css/style.css";
import { authentication } from "./store/actions/AuthActions";

function App(props) {

  let token = apiConfig.api_key;
  let routes = (
    <Switch>
      <Route exact={true} path='/login' component={Login} />
      <Redirect to='/login' />
    </Switch>
  );

  const [isLoggedIn, setLogin] = useState(false);

  useEffect(() => {
    console.log(authentication());
    setLogin(authentication());
    // setLogin(true);
  }, [isLoggedIn])

  return (
    <>
      <Suspense fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
      >
        {/* {routes} */}
        {isLoggedIn ? <Index /> : routes}
      </Suspense>
    </>
  );

};

export default App;