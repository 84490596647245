import apiConfig from "../config/api.config";
import Store from "../store/store";

export function getHashCode() {
    if (typeof getRecordInLocalStorage('user-status') != 'undefined') {
        return getRecordInLocalStorage('user-status')['hash_code'];
    } else {
        return getRecordInLocalStorage();
    }
}

export function getToken() {
    if (typeof getRecordInLocalStorage('user-status') != 'undefined') {
        return getRecordInLocalStorage('user-status')['idToken'];
    } else {
        return getRecordInLocalStorage();
    }
}

export function getUserID() {
    if (typeof getRecordInLocalStorage('user-status') != 'undefined') {
        return getRecordInLocalStorage('user-status')['user_id'];
    } else {
        return getRecordInLocalStorage();
    }
}

export function isLoggedIn() {
    if (typeof getRecordInLocalStorage('user-status') != 'undefined') {
        return getRecordInLocalStorage('user-status')['is_logged'];
    } else {
        return getRecordInLocalStorage();
    }
}

export function saveRecordInLocalStorage(key, data) {
    Store.set(key, JSON.stringify(data), apiConfig.exdays);
    return key;
}

export function removeRecordInLocalStorage(key) {
    Store.remove(key, '');
}

export function getRecordInLocalStorage(key) {
    if (Store.get(key) != '') {
        const data = JSON.parse(Store.get(key));
        return data;
    }
}

export function clearCookie() {
    removeRecordInLocalStorage('user-status');
    removeRecordInLocalStorage('survey-list-record');
    removeRecordInLocalStorage('voter-list-record');
}