import swal from "sweetalert";
import { questionAnswer, saveQuestionAnswer } from "../../services/QAService";
import { getRecordInLocalStorage, saveRecordInLocalStorage } from "../../services/UtilService";

export function questionAnswerAction(survey_hash_id, voter_hash_id) {
    return questionAnswer(survey_hash_id, voter_hash_id).then((response) => {
        if (response.status == 200) {
            //console.log(response.data);
            return response.data;
            //saveRecordInLocalStorage('voter-list-record', response.data);
            console.log(getRecordInLocalStorage('voter-list-record'));
        }
    });
}

export function saveQuestionAnswerAction(data) {
    return saveQuestionAnswer(data).then((response) => {
        if (response.status == 200) {
            return response.data;
        }
    });
}