import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";


// Layout
import Nav from "./layouts/navigation";
import Footer from "./layouts/Footer";

// Component
import Dashboard from "./components/dashboard/Dashboard";
import Error404 from "./pages/Error404";
import SurveyList from "./components/table/SurveyList/SurveyList";
import VoterList from "./components/table/VoterList/VoterList";
import QuestionAnswer from "./components/form/QuestionAnswer/QuestionAnswer";

import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {

    const { menuToggle } = useContext(ThemeContext);

    const routes = [
        { url: "", component: Dashboard },
        { url: "survey-list", component: SurveyList },
        { url: "voter-list/:id", component: VoterList },
        { url: "question-with-answer/:survey_hash_id/:voter_hash_id/:votername", component: QuestionAnswer },
        { url: "error-404", component: Error404 },
    ];

    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let pagePath = path.split("-").includes("page");
    return (
        <>
            <div
                id={`${!pagePath ? "main-wrapper" : ""}`}
                className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""}`}
            >
                {!pagePath && <Nav />}

                <div className={`${!pagePath ? "content-body" : ""}`}>
                    <div
                        className={`${!pagePath ? "container-fluid" : ""}`}
                        style={{ minHeight: window.screen.height - 60 }}
                    >
                        <Switch>
                            {routes.map((data, i) => (
                                <Route
                                    key={i}
                                    exact
                                    path={`/${data.url}`}
                                    component={data.component}
                                />
                            ))}
                            <Redirect to='/' />
                        </Switch>
                    </div>
                </div>
                {!pagePath && <Footer />}
            </div>
        </>
    );
}

export default Markup;