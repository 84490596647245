import axios from 'axios';
import apiConfig from '../config/api.config';
import { getHashCode, getToken } from './UtilService';

export function questionAnswer(survey_hash_id, voter_hash_id) {
    let token = getToken();

    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return axios.get(
        apiConfig.based_url + "admin/survey/questionsWithAnswers/" + getHashCode() + "/" + survey_hash_id + "/" + voter_hash_id,
        headerConfig
    );
}

export function saveQuestionAnswer(data) {
    let token = getToken();

    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }

    const bodyParams = data;

    return axios.post(
        apiConfig.based_url + "admin/survey/submitSurveyAnswers/" + getHashCode(),
        bodyParams,
        headerConfig
    );
}