import React from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import profile from "../../../images/avatar/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import Logout from "./Logout";

const Header = ({ onNote }) => {
    var path = window.location.pathname.split("/");
    var name = path[path.length - 1].split("-");
    var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
    var finalName = filterName.includes("app")
    console.log(finalName);

    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div className="dashboard_bar" style={{ textTransform: "capitalize" }}>
                                Dashboard
                                {/* {filterName.join(" ").length == 0 ? "Dashboard" : filterName.join(" ")} */}
                            </div>
                        </div>
                        <ul className="navbar-nav header-right main-notification">
                            <li className="nav-item">
                                <div className="input-group search-area">
                                    <input type="text" className="form-control" placeholder="Search Here" />
                                    <span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
                                </div>
                            </li>
                            <Dropdown as="li" className="nav-item dropdown header-profile">
                                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                                    <img src={profile} width={20} alt="" />
                                    <div className="header-info ms-3">
                                        <span>John Doe</span>
                                        <small>Superadmin</small>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
                                    <Link to="/app-profile" className="dropdown-item ai-icon">
                                        <svg
                                            id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary"
                                            width={18} height={18} viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                            <circle cx={12} cy={7} r={4} />
                                        </svg>
                                        <span className="ms-2">Profile </span>
                                    </Link>
                                    <Logout />
                                </Dropdown.Menu>
                            </Dropdown>


                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;