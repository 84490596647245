import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
 
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
    return (
        <div className="nav-header">
            <Link to="/dashboard" className="brand-logo">
                <Fragment>
                <img src={process.env.PUBLIC_URL + '/images/logo-with-text.png'} height={70} />
                </Fragment>
            </Link>

            <div
                className="nav-control"
                onClick={() => {
                    setToggle(!toggle);
                    openMenuToggle()
                }}
            >
                <div className={`hamburger ${toggle ? "is-active" : ""}`}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>
        </div>
    );
}
export default Navbar;