import React, { useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './FilteringTable/Columns';
import { GlobalFilter } from './FilteringTable/GlobalFilter';
import './FilteringTable/filtering.css';
import { getRecordInLocalStorage } from '../../../../services/UtilService';
import { Link } from 'react-router-dom';
import { surveyListAction } from '../../../../store/actions/SurveyAction';

const SurveyList = (props) => {

	// const [data, setData] = useState([]);
	const columns = useMemo(() => COLUMNS, [])
	const data = useMemo(() => getRecordInLocalStorage('survey-list-record'), [])

	useEffect(() => {
		surveyListAction();
		console.log(columns);
	}, []);

	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

	const { globalFilter, pageIndex } = state

	return (
		<>
			{/* <PageTitle activeMenu="Filtering" motherMenu="Table" /> */}
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Survey List</h4>
				</div>
				<div className="card-body">
					<div className="table-responsive">
						<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
						<table {...getTableProps()} className="table  display">
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()} className="" >
								{page.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												console.log(cell);
												if (cell.column.id != 'survey_hash_id') {
													return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
												} else {
													return <td {...cell.getCellProps()}>
														<Link to={`/voter-list/` + cell.value} className="btn btn-primary btn-sm">
															Voter List
														</Link>
													</td>
												}
												console.log(cell.column.id);

											})}
										</tr> 
									)
								})}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number"
									className="ml-2"
									defaultValue={pageIndex + 1}
									onChange={e => {
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
										gotoPage(pageNumber)
									}}
								/>
							</span>
						</div>
						<div className="text-center">
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SurveyList;